<template>
  <article class="lookbook_banner">
    <div v-if="urls.length > 1" class="nav">{{ navText }}</div>
    <div class="sinner">
      <swiper class="prod_swiper pop_swiper" :options="options">
        <swiper-slide v-for="(url, idx) in urls" :key="idx" class="prod_box">
          <image-bg-src :src="url" class="prod_img" />
        </swiper-slide>
        <div slot="scrollbar" class="swiper-scrollbar"></div>
      </swiper>
    </div>
  </article>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import ImageBgSrc from '../ImageBlock/ImageBgSrc.vue';
import { ref } from 'vue-demi';

export default {
  components: {
    Swiper,
    SwiperSlide,
    ImageBgSrc,
  },
  props: ['urls', 'activeIndex'],
  setup(props) {
    const navText = ref(`${props.activeIndex + 1}/${props.urls.length}`);

    return {
      navText,
      options: {
        slidesPerView: 'auto',
        watchOverflow: true,
        initialSlide: props.activeIndex,
        scrollbar: {
          el: '.swiper-scrollbar',
        },
        on: {
          transitionEnd() {
            navText.value = `${this.activeIndex + 1}/${this.slides.length}`;
          },
        },
      },
    };
  },
};
</script>

<style></style>

<template>
  <a class="books_box" @click="handleClick">
    <image-bg-src :src="item.cit_file_1" class="books_img" />
    <div class="books_text">
      <h6 class="books_title font6">{{ item.banner_title }}</h6>
      <p class="books_sub">
        {{ item.banner_sub_title }}
      </p>
    </div>
  </a>
</template>

<script>
import ImageBgSrc from '../ImageBlock/ImageBgSrc.vue';
export default {
  props: ['item'],
  components: { ImageBgSrc },
  methods: {
    handleClick() {
      this.$router.push(`/brand/look-book/${this.item.spp_id}`);
    },
  },
};
</script>

<style></style>

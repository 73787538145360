<template>
  <div class="wrap">
    <div v-if="item" class="look-book-detail">
      <header>
        <button
          type="button"
          class="share_btn btn_pop_open"
          style="z-index: 100"
          @click="handleClickShare"
        />

        <!-- <search-cart-icons class="search-cart-icons" /> -->
        <router-link
          :to="`/brand/detail/${item.provider.den_id}`"
          class="brand-item"
        >
          <div class="con pro_desc_box type2 type3">
            <image-bg-src class="profile_img" :src="item.provider.img_url" />
            <div style="background-image: url(../images/design/img5.png)"></div>
            <div class="profile_user">
              <h3 class="name">{{ item.provider.den_en_name }}</h3>
              <p class="desc">{{ item.provider.den_name }}</p>
            </div>
          </div>
        </router-link>
        <button
          type="button"
          class="leftclose btn_dim_close"
          @click="handleClickBack"
        />
      </header>
      <div>
        <image-swiper :urls="urls" :activeIndex="0" />
        <article class="look_info">
          <div class="con">
            <h3 class="look_tit font_Eng">{{ item.banner_title }}</h3>
            <p id="look_book_sub_title" v-html="item.contents" />
          </div>
        </article>
        <article class="look_product type2 item">
          <ul class="look_pro_list disflex fontEn">
            <li class="one">
              <button class="products_list current">
                {{ tab ? 'Other Lookbooks' : 'PRODUCTS' }}
              </button>
            </li>
            <li class="two">
              <button class="lookbooks_list" @click="tab = !tab">
                {{ tab ? 'PRODUCTS' : 'Other Lookbooks' }}
              </button>
            </li>
          </ul>
          <div v-show="!tab" class="prod_swiper noswiper_prod">
            <ul>
              <li v-for="item in products" :key="item.cit_id">
                <product-item :item="item" :bookMark="true" />
              </li>
            </ul>
          </div>

          <div v-show="tab" class="prod_swiper noswiper_prod">
            <ul>
              <li v-for="item in lookbooks" :key="item.spp_id">
                <look-book-order-item :item="item" />
              </li>
            </ul>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, watch } from 'vue-demi';
import ImageBgSrc from '../../../components/ui/ImageBlock/ImageBgSrc.vue';
import ImageSwiper from '../../../components/ui/ImageSwiper/ImageSwiper.vue';
import BrandAPI from '../../../apis/BrandAPI';
import ProductAPI from '../../../apis/ProductAPI';
import ProductItem from '../../../components/ui/ProductItem/ProductItem.vue';
import LookBookOrderItem from '../../../components/ui/LookBookOrderItem/LookBookOrderItem.vue';
import useShare from '../../../composables/dialogs/useShare';
// import SearchCartIcons from '../../../components/layouts/components/headers/components/RightHeader/SearchCartIcons.vue';

export default {
  props: ['spp_id'],
  components: {
    ImageBgSrc,
    ImageSwiper,
    ProductItem,
    LookBookOrderItem,
    // SearchCartIcons,
  },
  setup(props, context) {
    const state = reactive({
      urls: [],
      item: null,
      products: [],
      lookbooks: [],
      tab: false,
      isShowSnsShare: false,
    });
    // console.log("props.spp_id",props.spp_id)

    // props.spp_id &&
    //   onMounted(() => {
    //     setTimeout(() => {
    //       context.root.$router.push(`/brand/look-book/${props.spp_id}`);
    //     }, 1000);
    //   });

    const { showShare } = useShare();

    const createUrls = () => {
      const urls = [];

      for (let i = 1; i <= 10; i++) {
        if (state.item[`cit_file_${i}`]) {
          urls.push(state.item[`cit_file_${i}`]);
        } else {
          break;
        }
      }

      state.urls = urls;
    };

    const loadLookBookDetail = async () => {
      const spp_id = props.spp_id
        ? props.spp_id
        : context.root.$route.params.id;

      const { data } = await BrandAPI.getLookBookItem({
        spp_id,
      });

      state.item = data.result_data;

      createUrls();

      if (state.item !== null) {
        loadOrderLookbooks();
        loadProducts();
      }
    };

    loadLookBookDetail();

    const loadOrderLookbooks = async () => {
      const { data } = await BrandAPI.getOrderLookBooks({
        den_id: state.item.den_id,
        spp_id: state.item.spp_id,
      });

      state.lookbooks = data.result_data ? data.result_data : [];
    };

    const loadProducts = async () => {
      const { data } = await ProductAPI.getItemByProductIds({
        product_ids: state.item.product_id,
      });
      state.products = data.result_data ? data.result_data : [];
    };

    watch(
      () => context.root.$route.params.id,
      () => {
        loadLookBookDetail();
      }
    );
    return {
      ...toRefs(state),
      handleClickBack() {
        props.spp_id
          ? context.emit('close')
          : context.root.$router.replace('/brand/look-book');
      },
      handleClickShare() {
        showShare({
          url: `${location.origin}/brand/look-book/${state.item.spp_id}`,
          title: state.item.banner_title,
          image: `${process.env.VUE_APP_FILE_URL}${state.item.cit_file_1}`,
          desc:
            state.item.banner_sub_title === null
              ? ''
              : state.item.banner_sub_title,
        });
      },
    };
  },
};
</script>

<style>
.look-book-detail header {
  position: fixed;
  width: 100%;
  background: #fff;
  z-index: 100;
  top: 0;
}

.look-book-detail .pro_desc_box .profile_user {
  padding-left: 60px;
}

.look-book-detail .lookbook_banner {
  margin-top: 60px;
  position: relative;
}
.look-book-detail .lookbook_banner .prod_img {
  position: relative;
  padding: 131% 0 0;
  background: no-repeat center;
  background-size: cover;
}
.look-book-detail .lookbook_banner .nav {
  -webkit-transform: translateZ(0);
  position: absolute;
  z-index: 10;
  top: 10px;
  right: 10px;
  opacity: 0.6;
  border-radius: 15px;
  width: 50px;
  height: 30px;
  top: 10px;
  right: 14px;
  text-align: center;
  background-color: #202020;
  color: #fff;
  vertical-align: -4px;
  font-size: 12px;
  line-height: 30px;
  letter-spacing: 1px;
}
.look-book-detail .full_layout_pop .pop_body .sinner {
  overflow: hidden;
  padding: 0;
}
.look-book-detail .full_layout_pop .pop_body .sinner .swiper-scrollbar {
  width: 80%;
  bottom: 20px;
  z-index: 10;
  margin: 0 auto;
}
.search-cart-icons {
  position: absolute;
  right: 44px;
  bottom: 14px;
  z-index: 100;
}

.search-cart-icons > * {
  margin-left: 20px;
}
#look_book_sub_title div {
  font-size: 12px;
}
</style>
